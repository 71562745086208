import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { ContentSidePadding } from "components/ContentSidePadding";

const adblockers = [
  {
    name: "Adblock",
    instructions: [
      <p>Click on the Adblock application icon in your extensions.</p>,
      <p>
        Within the menu, click on <b className="text-white">"Pause on this site"</b>, which will disable ads on{" "}
        <span className="text-white">u.gg</span> only.
      </p>,
      <p>The page will reload, and you're good to go!</p>,
    ],
  },
  {
    name: "uBlock Origin",
    instructions: [
      <p>Click on the uBlock Origin application icon in your extensions.</p>,
      <p>
        Within the menu, click the big <b className="text-white">"power"</b> button, which will disable ads on{" "}
        <span className="text-white">u.gg</span> only.
      </p>,
      <p>Reload the page and you're good to go!</p>,
    ],
  },
  {
    name: "AdGuard",
    instructions: [
      <p>Click on the AdGuard application icon in your extensions.</p>,
      <p>
        Within the menu, click the big <b className="text-white">"power"</b> button, which will disable ads on{" "}
        <span className="text-white">u.gg</span> only.
      </p>,
      <p>Reload the page and you're good to go!</p>,
    ],
  },
];

const AdWhitelist = () => {
  useEffect(() => {
    window?.scrollTo(0, 0);
  }, []);

  return (
    <ContentSidePadding>
      <div className="mx-auto my-[40px] w-full max-w-[1014px]">
        <Helmet>
          <title>How to Whitelist U.GG</title>
        </Helmet>
        <div>
          <div className="mb-[28px] text-[42px] text-white font-bold">How to Whitelist U.GG</div>
          <div className="mb-[38px] text-[18px] text-accent-gray-100 leading-[24px]">
            {`Select your adblocker from the list below and follow the instructions to whitelist U.GG. ` +
              `Don't see the application or extension you're using? Please refer to your adblocker's website for more ` +
              `information on how to whitelist us.`}
          </div>
          <div className="flex flex-col">
            {adblockers.map((ext) => (
              <div
                key={ext.name}
                className="self-start text-[20px] font-bold text-white cursor-pointer hover:underline"
                onClick={(e) => {
                  const el = document?.getElementById(ext.name);
                  const yOffset = -200;
                  const y = (el?.getBoundingClientRect?.()?.top || 0) + window.scrollY + yOffset;

                  window.scrollTo({ top: y, behavior: "smooth" });
                }}
              >
                {ext.name}
              </div>
            ))}
          </div>
        </div>
        <div className="my-[36px] w-full h-[1px] bg-[#2c2c40]"></div>
        {adblockers.map((ext) => (
          <div key={ext.name} id={ext.name} className="mb-[24px]">
            <div className="mb-[18px] text-[20px] text-white font-bold">{ext.name}</div>
            <div>
              {ext.instructions.map((el, index) => (
                <div className="flex h-full mb-[12px] text-[18px]" key={index}>
                  <div className="mr-[18px] font-bold text-accent-orange-700">{index + 1}.</div>
                  <div className="text-accent-gray-100">{el}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </ContentSidePadding>
  );
};

export default AdWhitelist;
