import { window } from "global";
import { useGlobal } from "reactn";
import { useApolloClient } from "@apollo/client";
import gql from "graphql-tag";
import { addCookie, removeCookie } from "@ugg/shared/utils/cookies";
import { UserState, UserStateSchema } from "./user-state";

const LOGIN = gql`
  query Login(
    $email: String!,
    $password: String!,
  ) {
    login(
      email: $email,
      password: $password,
    ) {
      ${UserStateSchema}
    }
  }
`;

export interface LoginData {
  login: UserState;
}

export const useLogin = (
  creds: { email: string; password: string; persistLogin?: boolean },
  onCompleted?: (data: LoginData) => any,
  onError?: (error: any) => any,
) => {
  const client = useApolloClient();
  const [_authToken, setAuthToken] = useGlobal("authToken");
  const [_loggingIn, setLoggingIn] = useGlobal("loggingIn");

  return () => {
    setLoggingIn(true);
    client
      .query<LoginData>({
        query: LOGIN,
        variables: creds,
        errorPolicy: "all",
      })
      .then((response) => {
        const { data, errors } = response;
        if (errors) {
          setLoggingIn(false);
          setAuthToken(null);
          onError && onError(errors[0].message);
        } else {
          const { login } = data;
          const { loginState } = login;
          const { token } = loginState;
          const isPremium = login?.premium?.isPremium;

          if (window) {
            if (isPremium) {
              if (process.env.UGG_PUBLIC_PLATFORM !== "app") {
                localStorage.setItem("dontLoadGoogleCMP", "true");
              }
            }

            // setUserStateAnalytics(login);
            if (creds.persistLogin) {
              addCookie("authToken", token);
            } else {
              addCookie("authToken", token, true);
            }
            setAuthToken(token);
          }
          onCompleted && onCompleted(data);
        }
        setLoggingIn(false);
      })
      .catch((error) => {
        setLoggingIn(false);
        setAuthToken(null);
        onError && onError(error);
      });
  };
};

// ------------------------------------------------------------------------------------------------------------------------

const LOGOUT = gql`
  query {
    logout {
      success
    }
  }
`;

export interface LogoutData {
  logout: { success: boolean };
}

export const useLogout = (callback?: Function) => {
  const client = useApolloClient();
  const [authToken, setAuthToken] = useGlobal("authToken");
  return () => {
    client.query<LogoutData>({ query: LOGOUT, context: { clientName: "auth-api" } }).then((response) => {
      const { data, errors } = response;

      if (errors) return;

      const { logout } = data;
      if (logout.success) {
        // clearAnalytics();
      }

      if (window) {
        removeCookie("authToken");
        setAuthToken(null);

        if (process.env.UGG_PUBLIC_PLATFORM !== "app") {
          localStorage.removeItem("dontLoadGoogleCMP");
          window.location.reload();
        }
      }
      callback && callback();
    });
  };
};
