import { window } from "global";
import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useGlobal } from "reactn";
import { Helmet } from "react-helmet-async";
import { useMutation } from "@apollo/client";
import CustomInput from "components/CustomInput";
import PasswordChecker from "components/PasswordChecker";
import SummonerFinder from "components/SummonerFinder";
import ButtonSuccess from "components/Buttons/ButtonSuccess";

import { parseQueryString } from "@ugg/shared/utils/url-query-params";
import { addCookie } from "@ugg/shared/utils/cookies";
import { isValidEmail, isValidPassword } from "lib/form-helpers";
import { CREATE_ACCOUNT } from "@ugg/shared/api/graphql/queries";
import { pages } from "lib/redirect-helpers";
import { splitRiotId } from "@ugg/shared/utils/riot-id-helpers";

export const SignupButton = (props) => {
  return (
    <Link className="signup-btn" to="/signup">
      {props.children}
    </Link>
  );
};

export const Signup = (props) => {
  const history = useHistory();
  const location = useLocation();
  const search = location.search.substring(1);
  const parsedQueryString = parseQueryString(search);
  const [clientRegion] = useGlobal("clientRegion");

  const [showPasswordChecker, setPasswordChecker] = useState(false);
  const [signupError, setSignupError] = useState("");

  // Form
  const [form, setForm] = useState({
    email: { value: "", error: "" },
    password: { value: "", error: "" },
    riotId: { value: "", error: "" },
    summonerRegion: { value: clientRegion || "", error: "" },
    marketingOptOut: false,
  });

  useEffect(() => {
    window?.scrollTo(0, 0);
  }, []);

  const { email, password, riotId, summonerRegion, marketingOptOut } = form;

  const onCompleted = (data) => {
    const token = data.register.token;
    if (window) {
      addCookie("authToken", token);
      // localStorage.setItem("authToken", token);
      const signupVar = parsedQueryString.continue ? parsedQueryString.continue : "";
      const splitURL = signupVar.split("/");
      if (parsedQueryString.redirect_url) {
        window.location.href = `${parsedQueryString.redirect_url}`;
      } else if (splitURL.length === 1) {
        window.location.href = "/?onboarding=true";
      } else {
        window.location.href = `/boost/payment?planType=${splitURL.pop()}`;
      }
    }
  };

  const onError = (apolloError) => {
    const error = JSON.parse(JSON.stringify(apolloError));
    const { graphQLErrors } = error;

    if (graphQLErrors[0].message === "duplicate_user") {
      setSignupError("An account with this E-mail already exists.");
    }
    // Other errors
    else {
      console.error(graphQLErrors);
      setSignupError("An unexpected error occurred. Please try again.");
    }
  };

  const [inputRiotUserName, inputRiotTagLine] = splitRiotId(riotId.value);
  const [createAccount, { loading: creatingAccount, data: createdAccount, error: errorCreateAccount }] = useMutation(
    CREATE_ACCOUNT,
    {
      onCompleted,
      onError,
      variables: {
        email: email.value,
        marketingOptOut: marketingOptOut,
        password: password.value,
        riotUserName: inputRiotUserName,
        riotTagLine: inputRiotTagLine,
        summonerRegion: summonerRegion.value,
      },
      context: { clientName: "auth-api" },
    },
  );

  const onInputChange = (key, value) => {
    setForm({ ...form, [key]: { ...form[key], ...value } });
  };

  const onPasswordFocus = () => {
    setPasswordChecker(true);
  };

  const onPasswordBlur = () => {
    setTimeout(() => {
      setPasswordChecker(false);
    }, 100);
  };

  const switchToLogin = () => {
    let continuePath = [];
    if (parsedQueryString.continue) {
      continuePath.push(`continue=${parsedQueryString.continue}`);
    }
    if (parsedQueryString.redirect_url) {
      continuePath.push(`redirect_url=${parsedQueryString.redirect_url}`);
    }

    return `/login${continuePath.length > 0 ? `?${continuePath.join("&")}` : ""}`;
  };

  const verifyForm = () => {
    let verifiedForm = {};
    let hasError = false;
    function mergeForm(state, error) {
      if (error) {
        hasError = true;
      }
      return Object.assign(verifiedForm, state);
    }

    // Email
    if (email.value.trim().length === 0) {
      verifiedForm = mergeForm({ email: { ...email, error: "Email address is required" } }, true);
    } else if (!isValidEmail(email.value.trim())) {
      verifiedForm = mergeForm({ email: { ...email, error: "Invalid email address" } }, true);
    } else {
      verifiedForm = mergeForm({ email: { ...email, error: "" } });
    }

    // Password
    if (!isValidPassword(password.value)) {
      verifiedForm = mergeForm(
        { password: { ...password, error: "Password must be at least 8 characters, with at least one letter and one number" } },
        true,
      );
    } else {
      verifiedForm = mergeForm({ password: { ...password, error: "" } });
    }

    // Summoner Name
    if (!inputRiotUserName || !inputRiotTagLine) {
      verifiedForm = mergeForm({ riotId: { ...riotId, error: "Riot ID is required" } }, true);
    } else {
      verifiedForm = mergeForm({ riotId: { ...riotId, error: "" } });
    }

    const newForm = { ...form, ...verifiedForm };
    return { form: newForm, error: hasError };
  };

  const completeSignup = (e) => {
    e.persist();
    const verifiedForm = verifyForm();
    setForm({ ...form, ...verifiedForm.form });

    if (verifiedForm.error) return;

    setSignupError("");
    createAccount();
  };

  return (
    <div className="signup-outer">
      <Helmet>
        <title>U.GG - Signup</title>
      </Helmet>
      <div className="back-to-home">
        <Link to={pages["home"]}>&lt; Back to Home</Link>
      </div>
      <div className="signup">
        <form onSubmit={(e) => e.preventDefault()} className="signup_form">
          <div className="logo-container">
            <img
              className="logo"
              src="https://static.bigbrain.gg/assets/ugg/logo/ugg-logo.svg"
              onClick={() => history.push("/")}
            />
          </div>
          {/* {
            parsedQueryString["type"] === "premium_boost" &&
            <div className="premium-flow-header">
              <span>Ready for a </span>
              <span className="boost-text">BOOST</span>
              <span>?</span>
            </div>
          } */}
          <div className="sign">Create an account</div>
          <div className="already-have-account">
            Already have an account?{" "}
            <Link to={{ pathname: "/login", search }}>
              <span>Log In.</span>
            </Link>
          </div>
          {signupError && <div className="signup-error">{signupError}</div>}
          {
            <React.Fragment>
              <CustomInput
                value={email.value}
                error={email.error}
                onChange={(e) => onInputChange("email", { value: e.target.value })}
                placeholder="Email"
                type="email"
                tabIndex={1000}
                autoFocus
                style={{ marginBottom: 24 }}
                label={"Email"}
              />
              <CustomInput
                value={password.value}
                error={password.error}
                onChange={(e) => onInputChange("password", { value: e.target.value })}
                onFocus={onPasswordFocus}
                onBlur={onPasswordBlur}
                placeholder="Password"
                tabIndex={1001}
                type="password"
                label={"Password"}
                maxLength={128}
              />
              {showPasswordChecker && <PasswordChecker password={password.value} />}
              <div style={{ width: "100%", marginTop: 24 }}>
                {riotId.error && <div className="summoner-name-error">{riotId.error}</div>}
                <SummonerFinder
                  placeholder={"Summoner Name"}
                  riotId={riotId.value}
                  summonerRegion={summonerRegion.value}
                  onSummonerChange={(e) => onInputChange("riotId", { value: e.target.value })}
                  onRegionChange={(region) => onInputChange("summonerRegion", { value: region || "" })}
                  tabIndex={1002}
                  error={riotId.error}
                />
              </div>
              <div className="terms">
                By clicking on Create Account, you agree to our{" "}
                <Link className="forgot" to="/terms-of-service">
                  {"Terms of Service"}
                </Link>
                .
                <br />
                <br />
                To learn more about how U.GG collects, uses, shares and protects your personal data please read our{" "}
                <Link className="forgot" to="/privacy-policy">
                  {"Privacy Policy"}
                </Link>
                .
              </div>
              <ButtonSuccess
                className="signup-submit btn-light-blue"
                label="Create Account"
                isLoading={creatingAccount}
                onClick={completeSignup}
                bForceSubmitOnEnter={true}
              />
            </React.Fragment>
          }
        </form>
      </div>
    </div>
  );
};

export default Signup;
