import fetch from "node-fetch";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

import { DEFAULT_LANG, getUserLanguage } from "lib/locale-helpers";

const devTranslations = false;

function initI18next(i18nextInstance, language) {
  return new Promise((resolve, reject) => {
    i18nextInstance
      .use(Backend)
      .use(initReactI18next)
      .init(
        {
          react: {
            // wait: true,
            useSuspense: true,
          },
          lng: language || DEFAULT_LANG,
          fallbackLng: DEFAULT_LANG,
          //debug: true,
          backend: {
            loadPath: devTranslations
              ? "https://static.bigbrain.gg/assets/ugg/translations/dev/{{lng}}.json"
              : "https://static.bigbrain.gg/assets/ugg/translations/{{lng}}.json",
          },
          keySeparator: "|", // so that we can translate things with "." in them
          interpolation: {
            escapeValue: false, // not needed for react!!
          },
        },
        (error, t) => {
          if (error) {
            reject(error);
          } else {
            resolve(i18nextInstance);
          }
        },
      );
  });
}

export async function createI18nextInstance(language) {
  const initLang = getUserLanguage({ language });
  const newInstance = i18n.createInstance();
  try {
    await initI18next(newInstance, initLang);
  } catch (err) {
    console.error(err);
  }
  return Promise.resolve(newInstance);
}
