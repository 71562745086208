import { buildQueryParams } from "@ugg/shared/utils/url-query-params";
import { OVERVIEW_DEFAULT } from "@ugg/shared/query-params/filter-options/champions/overview";
import { TIER_LIST_DEFAULT } from "@ugg/shared/query-params/filter-options/tier-lists/tier-list";
import { AppRoutes } from "@ugg/shared/routes/app-routes";
import { RoleS } from "@ugg/shared/utils/role-helpers";

export function generateBuildAndRunes({ mmr, queueType, regionId }: { mmr?: string; queueType?: string; regionId?: string }) {
  let cleanFilters = {};

  if (mmr && mmr !== OVERVIEW_DEFAULT.rank) {
    cleanFilters = { ...cleanFilters, rank: mmr };
  }
  if (regionId && regionId !== OVERVIEW_DEFAULT.region) {
    cleanFilters = { ...cleanFilters, region: regionId };
  }
  if (queueType && queueType !== OVERVIEW_DEFAULT.queueType) {
    cleanFilters = { ...cleanFilters, queueType: queueType };
  }

  return buildQueryParams(cleanFilters);
}

export function generateTierList({
  mmr,
  queueType,
  regionId,
  role,
  showAllChampions,
}: {
  mmr?: string;
  queueType?: string;
  regionId?: string;
  role?: string;
  showAllChampions?: boolean;
}) {
  let cleanFilters = {};

  if (regionId && regionId !== TIER_LIST_DEFAULT.region) {
    cleanFilters = { ...cleanFilters, region: regionId };
  }
  if (showAllChampions) {
    cleanFilters = { ...cleanFilters, allChampions: "true" };
  }
  if (mmr && mmr !== TIER_LIST_DEFAULT.rank) {
    cleanFilters = { ...cleanFilters, rank: mmr };
  }
  if (role) {
    cleanFilters = { ...cleanFilters, role: role };
  }
  if (queueType && queueType !== TIER_LIST_DEFAULT.queueType) {
    cleanFilters = { ...cleanFilters, queueType: queueType };
  }

  return buildQueryParams(cleanFilters);
}

// Returns url tier list
export function convertToRoleTierList(filters: { role?: string }) {
  const getRolePathname = () => {
    switch (filters.role) {
      case RoleS.TOP:
        return AppRoutes.TOP_TIER_LIST;
      case RoleS.JUNGLE:
        return AppRoutes.JUNGLE_TIER_LIST;
      case RoleS.MIDDLE:
        return AppRoutes.MID_TIER_LIST;
      case RoleS.BOTTOM:
        return AppRoutes.ADC_TIER_LIST;
      case RoleS.SUPPORT:
        return AppRoutes.SUPPORT_TIER_LIST;
      default:
        return AppRoutes.DEFAULT_TIER_LIST;
    }
  };

  const noRole = { ...filters };
  delete noRole.role;

  return {
    pathname: getRolePathname(),
    search: buildQueryParams(noRole),
  };
}
