import { getRoleUrl, getColorRoleUrl } from "@outplayed/riot-assets";
import { ReactComponent as BotIcon } from "../assets/svg/roles/bot.svg";
import { ReactComponent as BotActiveIcon } from "../assets/svg/roles/bot-active.svg";
import { ReactComponent as BotInactiveIcon } from "../assets/svg/roles/bot-inactive.svg";
import { ReactComponent as SupportIcon } from "../assets/svg/roles/support.svg";
import { ReactComponent as SupportActiveIcon } from "../assets/svg/roles/support-active.svg";
import { ReactComponent as SupportInactiveIcon } from "../assets/svg/roles/support-inactive.svg";
import { ReactComponent as TopIcon } from "../assets/svg/roles/top.svg";
import { ReactComponent as TopActiveIcon } from "../assets/svg/roles/top-active.svg";
import { ReactComponent as TopInactiveIcon } from "../assets/svg/roles/top-inactive.svg";
import { ReactComponent as JungleIcon } from "../assets/svg/roles/jungle.svg";
import { ReactComponent as JungleActiveIcon } from "../assets/svg/roles/jungle-active.svg";
import { ReactComponent as JungleInactiveIcon } from "../assets/svg/roles/jungle-inactive.svg";
import { ReactComponent as MidIcon } from "../assets/svg/roles/mid.svg";
import { ReactComponent as MidActiveIcon } from "../assets/svg/roles/mid-active.svg";
import { ReactComponent as MidInactiveIcon } from "../assets/svg/roles/mid-inactive.svg";
import { ReactComponent as AllIcon } from "../assets/svg/roles/all.svg";
import { ReactComponent as AllInactiveIcon } from "../assets/svg/roles/all-inactive.svg";
import { ReactComponent as AramIcon } from "../assets/svg/roles/aram.svg";
import { ReactComponent as AramInactiveIcon } from "../assets/svg/roles/aram-inactive.svg";

// Role Alt 1
import { ReactComponent as AllAlt1Icon } from "../assets/svg/roles/alt-1/all.svg";
import { ReactComponent as BotAlt1Icon } from "../assets/svg/roles/alt-1/bot.svg";
import { ReactComponent as SupportAlt1Icon } from "../assets/svg/roles/alt-1/support.svg";
import { ReactComponent as TopAlt1Icon } from "../assets/svg/roles/alt-1/top.svg";
import { ReactComponent as JungleAlt1Icon } from "../assets/svg/roles/alt-1/jungle.svg";
import { ReactComponent as MidAlt1Icon } from "../assets/svg/roles/alt-1/mid.svg";
import { ReactComponent as AllInactiveAlt1Icon } from "../assets/svg/roles/alt-1/all-off.svg";
import { ReactComponent as BotInactiveAlt1Icon } from "../assets/svg/roles/alt-1/bot-off.svg";
import { ReactComponent as SupportInactiveAlt1Icon } from "../assets/svg/roles/alt-1/support-off.svg";
import { ReactComponent as TopInactiveAlt1Icon } from "../assets/svg/roles/alt-1/top-off.svg";
import { ReactComponent as JungleInactiveAlt1Icon } from "../assets/svg/roles/alt-1/jungle-off.svg";
import { ReactComponent as MidInactiveAlt1Icon } from "../assets/svg/roles/alt-1/mid-off.svg";
// Role Alt 2
import { ReactComponent as AllAlt2Icon } from "../assets/svg/roles/alt-2/all.svg";
import { ReactComponent as BotAlt2Icon } from "../assets/svg/roles/alt-2/bot.svg";
import { ReactComponent as SupportAlt2Icon } from "../assets/svg/roles/alt-2/support.svg";
import { ReactComponent as TopAlt2Icon } from "../assets/svg/roles/alt-2/top.svg";
import { ReactComponent as JungleAlt2Icon } from "../assets/svg/roles/alt-2/jungle.svg";
import { ReactComponent as MidAlt2Icon } from "../assets/svg/roles/alt-2/mid.svg";
import { ReactComponent as AllInactiveAlt2Icon } from "../assets/svg/roles/alt-2/all-off.svg";
import { ReactComponent as BotInactiveAlt2Icon } from "../assets/svg/roles/alt-2/bot-off.svg";
import { ReactComponent as SupportInactiveAlt2Icon } from "../assets/svg/roles/alt-2/support-off.svg";
import { ReactComponent as TopInactiveAlt2Icon } from "../assets/svg/roles/alt-2/top-off.svg";
import { ReactComponent as JungleInactiveAlt2Icon } from "../assets/svg/roles/alt-2/jungle-off.svg";
import { ReactComponent as MidInactiveAlt2Icon } from "../assets/svg/roles/alt-2/mid-off.svg";
// Role Alt 3
import { ReactComponent as RedAllAlt3Icon } from "../assets/svg/roles/alt-3/all.svg";
import { ReactComponent as RedBotAlt3Icon } from "../assets/svg/roles/alt-3/bot.svg";
import { ReactComponent as RedSupportAlt3Icon } from "../assets/svg/roles/alt-3/supp.svg";
import { ReactComponent as RedTopAlt3Icon } from "../assets/svg/roles/alt-3/top.svg";
import { ReactComponent as RedJungleAlt3Icon } from "../assets/svg/roles/alt-3/jungle.svg";
import { ReactComponent as RedMidAlt3Icon } from "../assets/svg/roles/alt-3/mid.svg";

// ------------------------------------
// Role API
// ------------------------------------

export enum RoleS {
  ALL = "all",
  TOP = "top",
  JUNGLE = "jungle",
  MIDDLE = "mid", // NOTE: might break things
  BOTTOM = "adc",
  SUPPORT = "support",
  NONE = "none",
  ARAM = "aram",
  URF = "urf",
  LANE = "lane",
}

export enum RoleS_Backend {
  ALL = "all",
  TOP = "top",
  JUNGLE = "jungle",
  MIDDLE = "mid",
  BOTTOM = "adc",
  SUPPORT = "supp",
  NONE = "none",
  ARAM = "aram",
  URF = "urf",
  LANE = "lane",
}

export enum RoleN {
  ALL = 7,
  TOP = 4,
  JUNGLE = 1,
  MIDDLE = 5,
  BOTTOM = 3,
  SUPPORT = 2,
  NONE = 6,
  ARAM = 0,
  URF = 0,
  LANE = 8,
}

export class RoleC {
  private readonly stringId: string;
  private readonly numId: number;
  private static RoleS_Obj = Object.fromEntries(Object.entries(RoleS));
  private static RoleS_BE_Obj = Object.fromEntries(Object.entries(RoleS_Backend));
  private static RoleN_Obj: Record<string, number> = Object.fromEntries(
    Object.entries(RoleN)
      .map(([key, value]) => [key, Number(value)])
      .filter((role) => typeof role[1] === "number" && !isNaN(role[1])),
  );

  constructor(stringValue: string, numValue: number) {
    this.stringId = stringValue;
    this.numId = numValue;
  }

  public static convertToInt(stringValue: string, backend?: boolean) {
    const roleObj = backend ? this.RoleS_BE_Obj : this.RoleS_Obj;
    for (const [key, value] of Object.entries(roleObj)) {
      if (value === stringValue) {
        return this.RoleN_Obj[key];
      }
    }
    return 0;
  }
  public static convertToString(numValue: number, backend?: boolean) {
    for (const [key, value] of Object.entries(this.RoleN_Obj)) {
      if (value === numValue) {
        return backend ? this.RoleS_BE_Obj[key] : this.RoleS_Obj[key];
      }
    }
    return "";
  }
  public getString() {
    return this.stringId;
  }
  public getInt() {
    return this.numId;
  }
}

export function getApiRole(role: string) {
  switch (role) {
    case "top":
      return RoleS_Backend.TOP;
    case "jungle":
      return RoleS_Backend.JUNGLE;
    case "mid":
    case "middle":
      return RoleS_Backend.MIDDLE;
    case "bottom":
    case "adc":
      return RoleS_Backend.BOTTOM;
    case "supp":
    case "support":
      return RoleS_Backend.SUPPORT;
    case "lane":
      return RoleS_Backend.LANE;
    default:
      return RoleS_Backend.ALL;
  }
}

export function getRevApiRole(role: string) {
  switch (role) {
    case "top":
      return RoleS.TOP;
    case "jungle":
      return RoleS.JUNGLE;
    case "mid":
    case "middle":
      return RoleS.MIDDLE;
    case "bot":
    case "bottom":
    case "adc":
      return RoleS.BOTTOM;
    case "supp":
    case "support":
      return RoleS.SUPPORT;
    case "lane":
      return RoleS.LANE;
    default:
      return RoleS.ALL;
  }
}

export function normalizeRole(role: string | number, defaultValue?: string) {
  switch (String(role)) {
    case String(RoleN.TOP):
    case "top":
      return RoleS.TOP;
    case String(RoleN.MIDDLE):
    case "middle":
    case "mid":
      return RoleS.MIDDLE;
    case String(RoleN.JUNGLE):
    case "jungle":
    case "jung":
      return RoleS.JUNGLE;
    case String(RoleN.BOTTOM):
    case "bottom":
    case "adc":
    case "bot":
      return RoleS.BOTTOM;
    case String(RoleN.SUPPORT):
    case "support":
    case "supp":
    case "sup":
      return RoleS.SUPPORT;
    case String(RoleN.LANE):
    case "lane":
      return RoleS.LANE;
    case String(RoleN.NONE):
    case RoleS.NONE:
      return RoleS.NONE;
    case String(RoleN.ALL):
    case "all":
      return RoleS.ALL;
    default:
      return defaultValue || (typeof role === "number" ? String(role) : role);
  }
}

export function isSRMainRole(role: string | number) {
  const mainRoles: string[] = [RoleS.TOP, RoleS.JUNGLE, RoleS.MIDDLE, RoleS.BOTTOM, RoleS.SUPPORT];
  return mainRoles.includes(normalizeRole(role));
}

// ------------------------------------
// Role Images/SVGs
// ------------------------------------

export function getRoleImg(role: string) {
  switch (normalizeRole(role)) {
    case RoleS.ALL:
      return getRoleUrl("all");
    case RoleS.TOP:
      return getRoleUrl("top");
    case RoleS.JUNGLE:
      return getRoleUrl("jung");
    case RoleS.MIDDLE:
      return getRoleUrl("mid");
    case RoleS.BOTTOM:
      return getRoleUrl("bot");
    case RoleS.SUPPORT:
      return getRoleUrl("supp");
    case RoleS.LANE:
      return getRoleUrl("bot");
    default:
      return undefined;
  }
}

export function getRoleSVG(role: string, params?: { active?: boolean; inactive?: boolean }) {
  const { active = false, inactive = false } = params || {};

  switch (normalizeRole(role)) {
    case RoleS.ALL:
      return inactive ? <AllInactiveIcon /> : <AllIcon />;
    case RoleS.TOP:
      return active ? <TopActiveIcon /> : inactive ? <TopInactiveIcon /> : <TopIcon />;
    case RoleS.JUNGLE:
      return active ? <JungleActiveIcon /> : inactive ? <JungleInactiveIcon /> : <JungleIcon />;
    case RoleS.MIDDLE:
      return active ? <MidActiveIcon /> : inactive ? <MidInactiveIcon /> : <MidIcon />;
    case RoleS.BOTTOM:
      return active ? <BotActiveIcon /> : inactive ? <BotInactiveIcon /> : <BotIcon />;
    case RoleS.SUPPORT:
      return active ? <SupportActiveIcon /> : inactive ? <SupportInactiveIcon /> : <SupportIcon />;
    case RoleS.LANE:
      return active ? <BotActiveIcon /> : inactive ? <BotInactiveIcon /> : <BotIcon />;
    case RoleS.ARAM:
      return inactive ? <AramInactiveIcon /> : <AramIcon />;
    default:
      return null;
  }
}

export function getRoleSVGAlt1(role: string, params?: { inactive?: boolean }) {
  const { inactive = false } = params || {};

  switch (normalizeRole(role)) {
    case RoleS.ALL:
      return inactive ? <AllInactiveAlt1Icon /> : <AllAlt1Icon />;
    case RoleS.TOP:
      return inactive ? <TopInactiveAlt1Icon /> : <TopAlt1Icon />;
    case RoleS.JUNGLE:
      return inactive ? <JungleInactiveAlt1Icon /> : <JungleAlt1Icon />;
    case RoleS.MIDDLE:
      return inactive ? <MidInactiveAlt1Icon /> : <MidAlt1Icon />;
    case RoleS.BOTTOM:
      return inactive ? <BotInactiveAlt1Icon /> : <BotAlt1Icon />;
    case RoleS.SUPPORT:
      return inactive ? <SupportInactiveAlt1Icon /> : <SupportAlt1Icon />;
    case RoleS.LANE:
      return inactive ? <BotInactiveAlt1Icon /> : <BotAlt1Icon />;
    default:
      return null;
  }
}

export function getRoleSVGAlt2(role: string, params?: { inactive?: boolean }) {
  const { inactive = false } = params || {};

  switch (normalizeRole(role)) {
    case RoleS.ALL:
      return inactive ? <AllInactiveAlt2Icon /> : <AllAlt2Icon />;
    case RoleS.TOP:
      return inactive ? <TopInactiveAlt2Icon /> : <TopAlt2Icon />;
    case RoleS.JUNGLE:
      return inactive ? <JungleInactiveAlt2Icon /> : <JungleAlt2Icon />;
    case RoleS.MIDDLE:
      return inactive ? <MidInactiveAlt2Icon /> : <MidAlt2Icon />;
    case RoleS.BOTTOM:
      return inactive ? <BotInactiveAlt2Icon /> : <BotAlt2Icon />;
    case RoleS.SUPPORT:
      return inactive ? <SupportInactiveAlt2Icon /> : <SupportAlt2Icon />;
    case RoleS.LANE:
      return inactive ? <BotInactiveAlt2Icon /> : <BotAlt2Icon />;
    default:
      return null;
  }
}

export function getRoleSVGAlt3(role: string) {
  switch (normalizeRole(role)) {
    case RoleS.ALL:
      return <RedAllAlt3Icon />;
    case RoleS.TOP:
      return <RedTopAlt3Icon />;
    case RoleS.JUNGLE:
      return <RedJungleAlt3Icon />;
    case RoleS.MIDDLE:
      return <RedMidAlt3Icon />;
    case RoleS.BOTTOM:
      return <RedBotAlt3Icon />;
    case RoleS.SUPPORT:
      return <RedSupportAlt3Icon />;
    case RoleS.LANE:
      return <RedBotAlt3Icon />;
    default:
      return null;
  }
}

export function getColorRoleImg(role: string, type: "blue" | "red") {
  switch (normalizeRole(role)) {
    case RoleS.ALL:
      return getColorRoleUrl("all", type);
    case RoleS.TOP:
      return getColorRoleUrl("top", type);
    case RoleS.JUNGLE:
      return getColorRoleUrl("jung", type);
    case RoleS.MIDDLE:
      return getColorRoleUrl("mid", type);
    case RoleS.BOTTOM:
      return getColorRoleUrl("bot", type);
    case RoleS.SUPPORT:
      return getColorRoleUrl("supp", type);
    case RoleS.LANE:
      return getColorRoleUrl("bot", type);
    default:
      return undefined;
  }
}

// ------------------------------------
// Other Role Utils
// ------------------------------------

export function getRoleLabel(role: string | number, altLabel?: (label: string) => string) {
  const fn = () => {
    switch (normalizeRole(role)) {
      case String(RoleN.JUNGLE):
      case "jungle":
        return "Jungle";
      case String(RoleN.SUPPORT):
      case "support":
      case "supp":
        return "Support";
      case String(RoleN.BOTTOM):
      case "bottom":
      case "adc":
        return "Bottom";
      case String(RoleN.TOP):
      case "top":
        return "Top";
      case String(RoleN.MIDDLE):
      case "mid":
      case "middle":
        return "Mid";
      case String(RoleN.NONE):
        return "";
      case String(RoleN.ALL):
      case "all":
        return "All Roles";
      case RoleS.ARAM:
        return "ARAM";
      case RoleS.URF:
        return "URF";
      case String(RoleN.LANE):
      case RoleS.LANE:
        return "Lane";
      default:
        return "";
    }
  };

  const label = fn();
  return altLabel ? altLabel(label) : label;
}

export const sortTeamByRoles = <T extends {}>(team: Array<T & { role: number | undefined | null }>) => {
  const teamOrder = [RoleN.TOP, RoleN.JUNGLE, RoleN.MIDDLE, RoleN.BOTTOM, RoleN.SUPPORT];

  return team.slice().sort((player1, player2) => {
    if (!player1.role || !player2.role) return 0;
    return teamOrder.indexOf(player1.role) - teamOrder.indexOf(player2.role);
  });
};

export function getRoleId(role: any) {
  switch (normalizeRole(role)) {
    case RoleS.ALL:
      return 7;
    case RoleS.TOP:
      return 4;
    case RoleS.JUNGLE:
      return 1;
    case RoleS.MIDDLE:
      return 5;
    case RoleS.BOTTOM:
      return 3;
    case RoleS.SUPPORT:
      return 2;
    case RoleS.LANE:
      return 8;
    default:
      return undefined;
  }
}

export function numToRoleMapping(num: number) {
  switch (Number(num)) {
    case 1:
      return RoleS.JUNGLE;
    case 2:
      return RoleS.SUPPORT;
    case 3:
      return RoleS.BOTTOM;
    case 4:
      return RoleS.TOP;
    case 5:
      return RoleS.MIDDLE;
    case 6:
      return RoleS.NONE;
    case 7:
      return RoleS.ALL;
    case 8:
      return RoleS.LANE;
    default:
      return "";
  }
}

// export function backendNumToRoleMapping(num) {
//   switch (Number(num)) {
//     case 1:
//       return ROLES_BACKEND.JUNGLE;
//     case 2:
//       return ROLES_BACKEND.SUPPORT;
//     case 3:
//       return ROLES_BACKEND.BOTTOM;
//     case 4:
//       return ROLES_BACKEND.TOP;
//     case 5:
//       return ROLES_BACKEND.MIDDLE;
//     case 6:
//       return ROLES_BACKEND.NONE;
//     case 7:
//       return ROLES_BACKEND.ALL;
//     default:
//       return "";
//   }
// }
