import { window } from "global";

const REGIONAL_URLS = ["na.u.gg", "eu.u.gg", "kr.u.gg", "sa.u.gg", "oce.u.gg"];

export function getGraphQlUrl() {
  let graphqlURI = process.env.UGG_PUBLIC_GRAPHQL_URI;
  if (window && window.location) {
    if (REGIONAL_URLS.includes(window.location.hostname)) {
      graphqlURI = `https://${window.location.hostname}/api`;
    }
  }
  return graphqlURI;
}

export function getAuthGraphQlUrl() {
  return process.env.UGG_PUBLIC_AUTH_GRAPHQL_URI || process.env.UGG_PUBLIC_GRAPHQL_URI;
}
