import { RoleN } from "@ugg/shared/utils/role-helpers";
import { RegionN } from "@ugg/shared/utils/region-helpers";
import { RankN } from "@ugg/shared/utils/rank-helpers";

type Wins = number;
type Matches = number;
type FirstPlace = number;
type TotalPlacement = number;

/* Runes ***********************************************************************/
type PrimaryStyle = number;
type SubStyle = number;
type ActivePerks = number[];

export type Runes = [Matches, Wins, PrimaryStyle, SubStyle, ActivePerks];

/* Stat Shards ***********************************************************************/
type ActiveShards = string[];

export type StatShards = [Matches, Wins, ActiveShards];

/* Summoner Spells **********************************************************************/
type CurrentSummonerSpells = [number, number];

export type SummonerSpells = [Matches, Wins, CurrentSummonerSpells];

/* Starting Items **********************************************************************/
type CurrentStartingItems = number[];

export type StartingItems = [Matches, Wins, CurrentStartingItems];

/* Core Items **********************************************************************/
type CurrentCoreItems = number[];

export type CoreItems = [Matches, Wins, CurrentCoreItems];

/* Skills **********************************************************************/
export type SkillPath = ("Q" | "W" | "E" | "R")[];
export type SkillPriority = string;

export type Skills = [Matches, Wins, SkillPath, SkillPriority];

/* Evolve Skill Path **********************************************************************/
type EvolvedSkillPath = string;
export type EvolvedSkills = [] | [Matches, Wins, EvolvedSkillPath];

/* Item Options **********************************************************************/
type ItemId = number;
export type Item = [ItemId, Wins, Matches];
export type PrismaticItem = [ItemId, FirstPlace, TotalPlacement, Wins, Matches];
export type ItemOption = Item[];
export type PrismaticOption = PrismaticItem[];
export type Consumables = Item[];
export type T3BootBuildOrder = number;
export type T3Boot = [`${T3BootBuildOrder}-${ItemId}`, Wins, Matches];
export type T3BootsOption = T3Boot[];

export type ItemsOptions = [
  ItemOption,
  ItemOption,
  ItemOption,
  Consumables | undefined,
  PrismaticOption | undefined,
  T3BootsOption | undefined,
];

/* Arena Synergies **********************************************************************/
type ArenaDuoChampionId = number;
type DuoTop2Matches = number;
type DuoTotalMatches = number;
type DuoTop1Matches = number;
type DuoTotalPlacements = number;
export type ArenaSynergy = [
  ArenaDuoChampionId,
  DuoTop2Matches,
  DuoTotalMatches,
  DuoTop1Matches | null,
  DuoTotalPlacements | null,
];

/* Arena Augments **********************************************************************/
type AugmentId = number;
type AugmentPickMatches = number;
type AugmentTotalMatches = number;
export type ArenaAugment = [AugmentId, AugmentPickMatches, AugmentTotalMatches];

export type BuildWins = number;
export type BuildMatches = number;
export type BuildWarning = boolean;
export type LastUpdated = number;

export type ChampionBuild = [
  [
    Runes,
    SummonerSpells,
    StartingItems,
    CoreItems,
    Skills,
    ItemsOptions,
    [BuildWins, BuildMatches],
    BuildWarning,
    StatShards,
    EvolvedSkills,
    ArenaSynergy[] | undefined,
    ArenaAugment[] | undefined,
  ],
  LastUpdated,
];

export type Overview = Record<RegionN, Record<RankN, Record<RoleN, ChampionBuild>>>;

export enum Multibuilds {
  RECOMMENDED = "recommended",
  AP = "ap",
  TANK = "tank",
  AD = "ad",
  CRIT = "crit",
  LETHALITY = "lethality",
  ONHIT = "onhit",
  REDKAYN = "red",
  BLUEKAYN = "blue",
}

export enum KaynFormIds {
  DEFAULT = 141,
  RED = 100141,
  BLUE = 200141,
}
