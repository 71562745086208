import classNames from "classnames";

export function ContentSidePadding({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}) {
  return <div className={classNames("px-[24px] max-md:px-[12px] max-xs:px-[8px]", className)}>{children}</div>;
}
