import React, { Component } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { getRiotAssetsContext, getIcon } from "@outplayed/riot-assets";
import { ReactComponent as ChevronLeft } from "@ugg/shared/assets/svg/chevron-left.svg";

const Error404 = (props) => {
  const { getChampionImg } = getRiotAssetsContext();
  const history = useHistory();
  const location = useLocation();

  const getClosestSearchMatch = () => {
    const searchResults = location && location.state && location.state.searchResults;
    if (Array.isArray(searchResults) && searchResults.length > 0) {
      return searchResults[0];
    }
    return null;
  };

  const didYouMean = getClosestSearchMatch();

  return (
    <div className="error-404 content-side-padding">
      {didYouMean && (
        <div className="did-you-mean">
          <img
            className="alert-icon"
            style={{ width: 40, marginRight: 15 }}
            src={getIcon("lol/icons/YUUWIECAREFULWATCHOUTALERTALERTGETDOOOOWN.svg")}
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <span>Did you mean to search for </span>
            <Link to={didYouMean.item.url}>
              <div className="champion-face">
                <img src={getChampionImg(didYouMean.item.id)} />
              </div>
              {didYouMean.item.key}
            </Link>
            <span>?</span>
          </div>
        </div>
      )}
      <div className="page-not-found">
        <div>
          <div>
            <div>
              <h1>THIS PAGE</h1>
              <h1>DOESN'T EXIST</h1>
            </div>
            <div className="grey-text" style={{ marginTop: 30 }}>
              <span>Just like your team's map awareness.</span>
            </div>
          </div>
          <hr />
          <div>
            <div>
              <span>The page you're looking for isn't available.</span>
            </div>
            <div>
              <span>Try to search again or use the back button.</span>
            </div>
            <div className="back-btn group cursor-pointer" onClick={() => history.goBack()}>
              <ChevronLeft className="w-[12px] group-hover:[&>path]:fill-accent-blue-400" />
              <strong className="ml-[14px] text-[24px] group-hover:text-accent-blue-400">BACK</strong>
            </div>
          </div>
        </div>
        <img className="img-404" src={require("images/404-dark.png").default} alt="404" />
      </div>
    </div>
  );
};

export default Error404;
